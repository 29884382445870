import React, { useMemo } from 'react'
import {
  Progress,
  useDocumentBuilderApi,
  withContext,
  DocumentEditorView,
  WrContextType,
  DocumentBuilderExportOptions,
  DocumentBuilderOptions,
  RouteComponentProps,
} from '@tools/wr-catalog-base'
import { getBuilderContext } from 'utils/proposals/getBuilderContext'
import { finalizeHtml, updateHtml } from 'utils/html/updateHtml'
import { fetchSOW } from 'utils/proposals/fetchSOW'

import { ModalDescription } from './style'
import { submitProposal } from 'utils/proposals/submitProposal'
import { getSelectedTemplate } from 'utils/proposals/getSelectedTemplate'

interface DocumentEditViewProps extends RouteComponentProps {
  context: WrContextType
}

const DocumentEditView = (props: DocumentEditViewProps) => {
  const {
    context: {
      state: {
        cartCalculations: { totalHours, totalWeeks },
      },
      actions: { openModal },
    },
  } = props

  const templateSelected = getSelectedTemplate(props.context)

  const config = useMemo(() => {
    /** @type {import('@tools/wr-catalog-base').DocumentBuilderOptions} */
    const res: DocumentBuilderOptions = {
      getContext: getBuilderContext,
      getTemplate: () => fetchSOW(templateSelected?.value),
      mutateFunctions: [updateHtml],
      finalizeFunctions: [finalizeHtml],
      save: ({ html, options }: { html: string; options: DocumentBuilderExportOptions }) => {
        openModal({
          showClose: false,
          body: (
            <>
              <ModalDescription>We are generating your proposal</ModalDescription>
              <Progress />
            </>
          ),
        })

        submitProposal(html, { [options.type]: true })
      },
    }

    return res
  }, [templateSelected?.value])

  const builderApi = useDocumentBuilderApi(config)

  return (
    <div data-testid="document-editor">
      <DocumentEditorView builder={builderApi} />
    </div>
  )
}

export default withContext(DocumentEditView)
